<template>
    <div>
        <v-row>
          <v-col cols="12">            
              <tile-pay-status></tile-pay-status>
          </v-col>
        </v-row>        
        <v-row v-if="companyLvType == 'PRO'">
          <v-col cols="12">            
              <tile-bill-list></tile-bill-list>
          </v-col>
        </v-row>   
    </div>
</template>
<script>
import { mapGetters } from 'vuex'


export default {
  name: 'pay_status',
  components: {
    'tilePayStatus': () => import('@/components/pay/tile_pay_status'),
    'tileBillList': () => import('@/components/pay/tile_bill_list'),
  },
  data: () => ({
  }),
  computed: {
      ...mapGetters({
          companyLvType: 'login/getCompanyLvType',
      }),
  },  

  created () { 
    this.doSearch()
  },
  methods: {
    doSearch () { 
      this.$store.dispatch('pay/getCompanyPay', {
        scb: (companyPay, billList, va) => { 
        }, fcb: (err, va) => { 
        }, va: this
      })
    }
  }
}
</script>

